import React from 'react';
import { styled } from '@mui/material/styles';
import { Flex, Text, Column, LinkExternal } from '../../../components';
import Router from '../../../routes/router';

const Footer = () => {
    return (
        <FooterContainer>
            <LeftSide>
                <NavigationTextContainer>
                    <LeftNavigationTextColumn>
                        <FooterLinkExternal href="https://uk.web.connectedriskengine.pwc.com/privacystatement.html"
                        >
                            Privacy statement
                        </FooterLinkExternal>
                        <FooterLinkExternal href="https://uk.web.connectedriskengine.pwc.com/cookiepolicy.html">
                            Cookie policy
                        </FooterLinkExternal>
                        <FooterLinkExternal href="https://www.pwc.co.uk/who-we-are/legal-disclaimer.html">
                            Legal disclaimer
                        </FooterLinkExternal>
                    </LeftNavigationTextColumn>
                    <Column>
                        <FooterButtonExternal onClick={Router.goToTermsOfUse}>
                            Terms of Use
                        </FooterButtonExternal>
                        <FooterLinkExternal href="https://uk.web.connectedriskengine.pwc.com/OSS-components.html">
                            OSS Components
                        </FooterLinkExternal>
                    </Column>
                </NavigationTextContainer>
                <LicenseText>
                    &copy; 2022 PwC All rights reserved. PwC refers to the PwC network
                    and/or one or more of its member firms, each of which is a
                    separate legal entity. Please see www.pwc.com/structure for
                    further details.
                </LicenseText>
            </LeftSide>
        </FooterContainer>
    );
};

const FooterContainer = styled(Flex)`
    background-color: #40403e;
    padding: 40px;
`;

const FooterLinkExternal = styled(LinkExternal)`
    margin-bottom: 22px;
`;

const FooterButtonExternal = styled(FooterLinkExternal)`
    padding: 0;
    background: none;
    border: none;
`;

FooterButtonExternal.defaultProps = {
    as: 'button'
};

const LicenseText = styled(Text)`
    font-size: 15px;
    color: #fff;
    line-height: 1.6;
`;

const LeftSide = styled(Column)`
    width: 100%;
`;

const NavigationTextContainer = styled(Flex)`
    margin-bottom: 16px;
`;

const LeftNavigationTextColumn = styled(Column)`
    margin-right: 120px;
`;

export default Footer;
